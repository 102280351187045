<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <button class="modal__close btn" @click="closeModal">
      <i class="bi bi-x"></i>
    </button>
    <span class="modal__title">{{ formTitle }}</span>
    <div class="modal__content py-2 px-2">
      <form @submit.prevent="submitForm" class="form">
        <div class="col-md-12">
          <label for="nama" class="form-label">Kode</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.code"
            :class="{ 'is-invalid': $v.editedItem.code.$error }"
          />
          <div v-if="$v.editedItem.code.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.code.required">Kode harus di isi.</span>
          </div>
        </div>
        <div class="col-md-12">
          <label for="nama" class="form-label">Nama</label>
          <input
            class="form-control"
            type="text"
            v-model="editedItem.name"
            :class="{ 'is-invalid': $v.editedItem.name.$error }"
          />
          <div v-if="$v.editedItem.name.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.name.required">Nama harus di isi.</span>
          </div>
        </div>
      </form>
    </div>
    <div class="modal__action mt-3">
      <button class="btn btn-primary mx-2" @click="submitForm">Simpan</button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VueFinalModal } from "vue-final-modal";
export default {
  components: {
    VueFinalModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedId: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Data Baru" : "Edit Data";
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("save");
      }
    },
  },
  validations: {
    editedItem: {
      code: {
        required,
      },
      name: {
        required,
      },
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
